@import 'config.scss';
@import 'mixins.scss';

.form-section {
    padding: $pad-sm 0;

    &:not(:last-of-type) {
        border-style: solid;
        border-color: $col-pret-blue;
        border-bottom-width: 1px;
    }
}

.form-group {
    padding: $pad-xsm 0;

    label, .label, .input-group {
        // margin-bottom: $pad-med;
        display: inline-block;
        width: 250px;
    }

    &:not(.no-collapse) {
        #{$sel-input}, .addressDetails, .input-group {
            width: 100%;

            // &:not(:last-of-type) {
                // margin-bottom: $pad-med;
            // }
        }
    }
    &.no-collapse {
        #{$sel-input}, .addressDetails, .input-group {
            width: 255px;
            // &:not(:last-of-type) {
                // margin-bottom: $pad-med;
            // }
        }

        .class-shared-input {
            width: 48% !important;
            margin-right: 3%;
        }

        .class-shared-input:last-child {
            margin-right: 0;
        }
    }


    button {
        width: 100%;
    }

    &.small {
        font-size: .85em;
    }

    h3 {
        font-size: 1em;
        font-weight: bold;

        margin-bottom: 1em;
    }

    p {
        margin-bottom: 1em;

        b {
            font-weight: bold;
        }
    }

    .input-group {

        input {
            width: 85% !important; /* Make the input a bit shorter to make room for the span*/
        }

        span {
            display: inline-block;
            width: 6%;
            text-align: center;
            color: $col-pret-blue;
            font-size: 24px;
            font-weight: bold;
            line-height: 40px;
            vertical-align: middle;

            &.fa {
                font-weight: normal;
            }

            &.green, &.success {
                color: $col-green;
            }

            &.red, &.danger {
                color: $col-red;
            }

            &.yellow, &.warning {
                color: $col-yellow;
            }


        }

    }
}

.vertical-align-top {
    vertical-align: top !important;
}

.half-width {
    width: 50%;
}

.inline-block {
    display: inline-block;
}

.addresses {
    display: block;
    width: 100%;
}

.centre {
    text-align: center;
}

.sr-only {
    display: none;
}

@media (min-width: $brk-desktop-xsm) {
    .form-group {
        button {
            width: auto;
        }

        #{$sel-input}, .addressDetails, .input-group {
            width: auto;
        }

        /* Override no-collapse on larger devices */
        &.no-collapse {
            #{$sel-input}, .addressDetails, .input-group {
                width: 400px;
            }
        }

        &.btns {
            width: auto;
        }

        .class-label {
            width: 30%;
        }

        .class-input           // Regular fields
        {
            width: 69% !important;
        }

        .full-width {
            width: 100% !important;
        }

        .class-shared-input {
            width: 48% !important;
            margin-right: 3%;
        }

        .class-shared-input:last-child {
            margin-right: 0;
        }

        input[type="checkbox"] {
            width: 25px !important;
        }

        a.button {
            margin-top: 0 !important;
        }

        p {
            max-width: 600px;
        }

        .register {
            max-width: 100% !important;
        }
    }

    .addresses {
        display: inline-block;
        margin-left: 3em;
        width: 45% !important;
    }
}

@media (max-width: $brk-tab-med) {
    .form-group {
        a.button {
            margin: 1em 1em 1em 0 !important;
        }
    }

    .form-group.no-collapse .class-shared-input {
        margin-right: 0;
    }
}

// .zoid-outlet { height: 200px; width: 400px; }
.zoid-outlet div iframe { border: none; }