.container {
    width: 95%;
}

@media(min-width: 468px) {
    .container {
        width: 448px;
    }
}

@media(min-width: 640px) {
    .container {
        width: 620px;
    }
}

@media(min-width: 768px) {
    .container {
        width: 748px;
    }
}

@media(min-width: 876px) {
    .container {
        width: 856px;
    }
}

@media(min-width: 1024px) {
    .container {
        width: 1004px;
    }
}

@media(min-width: 1280px) {
    .container {
        width: 1260px;
    }
}

@media(min-width: 1408px) {
    .container {
        width: 1388px;
    }
}

@media(min-width: 1600px) {
    .container {
        width: 1580px;
    }
}


@media(min-width: 1770px) {
    .container {
        width: 1750px;
    }
}
