@import 'config.scss';
@import 'mixins.scss';

#privacy {

    font-size: 0.85em;

    #privacy-more-trigger {
        color: $col-pret-blue;
        cursor: pointer;
        margin-bottom: 3.8em !important;
    }
}

#register {
    padding-bottom: 20px;
}

@media (min-width: $brk-desktop-xsm) {

    #register {
        padding-bottom: 70px;
    }
}

@media (max-width: $brk-desktop-sm) {
    #privacy {
        #privacy-more-trigger {
            margin-bottom: 0 !important;
        }
    }
}

.padding-bottom-70 {
    padding-bottom: 70px;
}

#register {

    #{$sel-input} {
        font-size: 1.2em;
        color: #000;
        width: 500px;
        height: 50px;
        padding: 5px 10px;
        text-align: left;
        border: 2px solid #01B055;
        margin: 10px 0;
        border-radius: 10px;
    }

        button {
        border: none;
        margin-top: 50px;
        padding: 15px 60px;
        font-size: 1.4em;
        text-align: center;
        border-radius: 10px;
    }

    .password-container {

        #{$sel-input} {
            width: 410px;
        }

        span {
            font-size: 2em;
            padding: 5px;
        }

        width: 500px;
        display: inline-block;
        text-align: left;
    }
}

@media (max-width: $brk-mob-lg) {

    #register {
        #{$sel-input} {
            width: 400px;
        }
    }
}

@media (max-width: $brk-mob-max) {
    #register {
        #{$sel-input} {
            width: 300px;
        }
    }
}

.bypass-register-container {
    margin-top: 20px;
}

#bypass-register {
    font-size: 1.2em !important;
    padding: 15px 40px !important;
    background-color: $col-pret-blue-light;
    color: $col-pret-blue;
    margin-top: 0 !important;
}