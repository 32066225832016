@import 'config.scss';
@import 'mixins.scss';

.image-icons {
    position: absolute;
    bottom: 5px;
    right: 5px;
    text-align: center;
}

.image-icons-favourite {
    position: absolute;
    top: 5px;
    right: 5px;
    text-align: center;
}

.image-icons-buy {
    position: absolute;
    bottom: 0px;
    right: 0px;
    text-align: center;
}

img.icon {
    height: auto;
    width: 1em !important;
}

.icon-heart {
    background-color: $col-grey-dark;
    display: inline-block;
    height: 2em;
    width: 2em;
    border-radius: 50%;
    border-width: 1px;
    border-color: darken($col-grey-dark, 10%);
    border-style: solid;

    &.full {
        background-color: $col-green;
        border-color: darken($col-green, 10%);
    }

    .icon {
        @include VerticalAlignMiddle();
        position: absolute;
        left: 25%;
    }
}

/*.icon-basket {
    background-color: $col-pret-blue;
    color: white !important;
    text-align: center;
    border-radius: 15px;
    padding: 6px 5px 5px 5px;
    width: 50px;
    height: 2em;
}*/