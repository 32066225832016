@import 'config.scss';
@import 'mixins.scss';

.animate {
    &.fade {
        @include Transition(all 0.5s linear);
        visibility: visible;
        opacity: 1;

        &.ng-hide {
            visibility: hidden;
            opacity: 0;
        }
    }
}

.fix-fade {
    margin: 0 auto;
    max-width: 125px;
}

@media (min-width: 350px) {
    .fix-fade {
        max-width: 180px;
    }
}

@media (min-width: $brk-tab-med) {
    .fix-fade {
        max-width: 320px;
    }
}

@media (min-width: $brk-desktop-xsm) {
    .fix-fade {
        max-width: 500px;
    }
}
