@import 'config.scss';
@import 'mixins.scss';

.basket-item {
    position: relative;
    margin-bottom: 50px;
    text-align: left;
    background-color: $col-white;
    border-radius: 5px;
    box-shadow: 2px 3px 8px 0px #dddddd;
    padding: 35px 35px;

    .basket-details {
        position: relative;
        vertical-align: top;
    }

    .basket-pip, .basket-pip-child, .basket-details {
        display: inline-block;
    }

    .basket-pip {
        img {
            @extend .default-image-wrapper;
            width: 200px;
            height: 200px;
            margin-right: 1em;
        }
    }

    .basket-pip-child {
        img {
            @extend .default-image-wrapper;
            width: 150px;
            height: 150px;
            margin-bottom: 1em;
            margin-right: 1em;
        }
    }

    .product-options {
        margin-bottom: $pad-global;
    }

    .basket-remove-section {
        position: absolute;
        right: $pad-med;
        top: $pad-med;
        text-align: right;
        padding: $pad-xsm;
        background-color: $col-grey;

        .remove-icon {
            font-size: 1.2em;
        }
    }

    .pictures-list {
        // We pass null because we are setting the max-height in the javascript.
        @include AnimateHeight(0, null, 'open');
    }

    .croppable-image {
        opacity: 0.5;
        z-index: 3;
    }

    .note {
        width: 75%;
        margin-bottom: 3em;

        &.required {
            border-color: $col-red;
        }

        &.highlight {
            animation: note-fade 2s;
            font-size: 1em;
            font-family: 'Century Gothic W01', "Century Gothic", CenturyGothic, AppleGothic, Tahoma, "Microsoft YaHei New", "Microsoft Yahei", "微软雅黑",宋体, SimSun, STXihei, "华文细黑", sans-serif;

      }
    }

    .highlight::placeholder {
         color: $col-white;
    }

    .highlight:-ms-input-placeholder {
         color: $col-white;
    }

    @keyframes note-fade {
        0% {
            background-color: $col-white
        }
    50% {
            background-color: $col-red
        }
        75% {
            background-color: $col-white
        }
    }

    .crop-instructions {
       margin-bottom: 1em;

        span {
            padding: 1em;
            border: 2px $col-red dashed;
            color:$col-red;
            background-color: $col-grey-light;
            display: inline-block;
            font-weight: bold;
        }
    }

    #background {
        background: #000;
        z-index: 0;
        margin: 0 auto;
    }

    #foreground {
        background: #fff;
    }

    #draggable-box, #foreground {
        position: absolute;
        align-content: center;
        padding: 0;
        margin: 0;
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
        display: block;
        touch-action: none;
    }

    #draggable-box {
        z-index: 4;
        border: 2px dashed black;
    }

    #top-left-handle {
        position: relative;
        width: 10px;
        height: 10px;
        background-color: black;
        float: left;
    }

    #top-right-handle {
        position: relative;
        width: 10px;
        height: 10px;
        background-color: black;
        float: right;
    }

    #bottom-left-handle {
        position: absolute;
        bottom: 0;
        width: 10px;
        height: 10px;
        background-color: black;
        float: left;
    }

    #bottom-right-handle {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 10px;
        height: 10px;
        background-color: black;
        float: right;
    }

    #drag-me::before {
        content: "#" attr(id);
        font-weight: bold;
    }
}

$checkout-height: 40px;

.basket-item-last {
    margin-bottom: $checkout-height + ($pad-med * 2);
}

.basket-confirmation {
    position: fixed;
    bottom: 60px;
    left: 0;
    right: 0;
    height: ($checkout-height * 2) + ($pad-med * 2);
    background-color: $col-grey-light;
    padding: $pad-med 10%;

    h2 {
        line-height: $checkout-height;
        display: inline-block;
    }

    button, a.button {
        display: inline-block;
        width: 100%;
    }
}

.heading-4 {
    padding: 0.5em 0;
    font-size: 1.2em;
    font-weight: bold;
}

@media (max-width: $brk-mob-lg) {
    .basket-confirmation {
        width: 100%;
    }
}

@media (min-width: $brk-mob-lg) {
    .basket-confirmation {
        height: $checkout-height + ($pad-med * 2);
        padding-left: 0;
        padding-right: 0;
        left: 0;
        right: 0;

        h2 {
            position: absolute;
            left: $pad-med;
        }

        button, a.button {
            position: absolute;
            right: $pad-med;
            width: auto;
        }
    }
}

@media (min-width: $brk-tab-med) {
    .basket-pip {
        img {
            width: 300px !important;
            height: 300px !important;
        }
    }
}

#paymentMethods {
    padding-bottom: 0;

    li {
        padding-bottom: 0;
    }
}

#paymentContainer {

    border: 1px solid black;
    background: #fff;
    padding: 1em;

    #static-wrapper {

        p {
            padding-bottom: 1em;
        }

    }
}

.checkout-module {

    display: block;

    .error {
        color: $col-red;
    }

    &.active {
        div.content {
            display: block;
            margin-bottom: 2em;
        }

    }

    h3{
        margin-bottom: 1em;
        padding: 0.5em 0.5em;
        background: $col-grey-dark;
        color: $col-white;

        &.lit {
            background-color: $col-pret-blue;
        }
   }

    div.content {

        display: none;

        div.box {

            background: $col-white;
            border: 1px solid $col-coal;
            padding: 2em;
        }

        p {
            margin-bottom: 1em;
            line-height: 1.5em;
        }

        .btn-half {
            width: 100%;
            margin-top: 1em;

            @media (min-width: $brk-mob-lg) {
                width: 48%;
                margin-right: 1em;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        .btn-width {
            display: block;
            width: 100%;
            margin-top: 1em;
        }

        .pre {
            white-space: pre;
            overflow: scroll;
        }
    }

    /* Tabbed content */
    .tab-bar {

        .button-list {

            padding-bottom: 0;

            li {
                display: inline-block;
                margin-right: 5px;
                padding-bottom: 0;

                button {
                    background-color: $col-grey-dark;
                }

                button.active {
                    background-color: $col-pret-blue;
                }
            }
        }

        tab {
            &.hidden {
                display: none;
            }
        }

    }

    .terms {
        label {
            input { vertical-align: middle; }
            p {
                display: inline-block;
                vertical-align: top;

                a {
                    vertical-align: baseline;
                }
            }
        }
    }
}

.c2a_mode2 .cc-flag {
    display: none;
}


/* CSS */
@supports (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
        display: inline-block;
        -webkit-appearance: -apple-pay-button;
    }
    .apple-pay-button-black {
        -apple-pay-button-style: black;
    }
    .apple-pay-button-white {
        -apple-pay-button-style: white;
    }
    .apple-pay-button-white-with-line {
        -apple-pay-button-style: white-outline;
    }
}

@supports not (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
        display: inline-block;
        background-size: 100% 60%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border-radius: 5px;
        padding: 0px;
        box-sizing: border-box;
        min-width: 200px;
        min-height: 32px;
        max-height: 64px;
    }
    .apple-pay-button-black {
        background-image: -webkit-named-image(apple-pay-logo-white);
        background-color: black;
    }
    .apple-pay-button-white {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
    }
    .apple-pay-button-white-with-line {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
        border: .5px solid black;
    }
}

.confirmCrop {

    h1 {
        margin-bottom: 1em;
    }

    p { margin-bottom: 1em; }

    .crop-logo {
        width: 250px;
        height: 250px;
        display: inline-block;
        position: relative;

        .crop-line {
            position: absolute;
            top: 20px;
            left:10px;
            right: 10px;
            bottom: 20px;
            border: 2px dashed $col-red;
        }

        .image-cropper {
        width: 250px;
        height: 250px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        }

        .fa {
            color: $col-white;
            background-color: $col-pret-blue;
            width:250px;
            height:250px;
            font-size: 220px;
            line-height: 250px;
        }
    }
}