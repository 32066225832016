@import 'config.scss';
@import 'mixins.scss';


.banner-top {
    display: flex;
    justify-content: space-between;
    min-height: 48px;
    gap: 0 3em;
    align-items: center;
}

@media (max-width: $brk-desktop-sm) {
    .banner-top {
        flex-wrap: wrap;
    }
}

.banner {
    background-color: $col-grey;
    min-height: $hgt-banner;
    height: auto;
    @include BoxShadowBottom();

    .banner-title {
        width: 100%;
        font-size: large;
        color: $col-pret-blue;
    }

    .banner-menu {
        float: right;
    }

    .banner-categories {
        display: flex;
        column-gap: 20px;
        font-weight: bold;
        color: $col-coal;
        padding-top: 0.4em;
        padding-bottom: 0.6em;
        text-align: center;
        white-space: nowrap;

        li {
            padding: 5px;

            &.active {
                border-bottom: 3px solid $col-pret-blue;
                margin-bottom: -3px;
                color: black;
            }
        }
    }

}

.banner-sitting {
    input {
        vertical-align: text-bottom;
    }

    button {
        vertical-align: top;
    }
}

@media (min-width: $brk-mob-lg) {
    .banner {
        text-align: center;
    }
}

@media (max-width: $brk-mob-max) {
    .banner-top {
        justify-content: center;
    }

    .banner {
        height: auto;
        display: block;

        .banner-categories {
            padding: .5em .5em;
            padding-bottom: 0.65em;
            justify-content: center;
            font-size: 0.9em;
        }
    }

}

.album-image-folders-container {
    display: block;
    position: relative;

    .arrow-left, .arrow-right {
        display: block;
        position: absolute;
        top: 0;
        bottom: 10px;
        width: 40px;
        padding: 0 10px;
        z-index: 900;
        i { vertical-align: middle;
            line-height: 180px;
            font-size: 32px; }
    }

    .arrow-left {
        left: 0;
        i {
            margin-left: -4px;
        }
    }

    .arrow-right {
        right: 0;
        text-align: right;
    }

    .album-image-folders {
        display: block;
        // height: 200px;
        height: auto;
        background: $col-grey;
        overflow-x: auto;
        @include StyleScrollbar;

        .album-image-folder-scroll {
            display:flex;
            flex-direction: row;
            width: 20000px; // Set this in JS?
        }

        .folder {
            display: inline-block;
            position: relative;
            min-width: 150px;
            min-height: 170px;
            width: 150px;
            height: 170px;
            margin: 10px;
            padding: 10px;
            padding-top: 8px;
            padding-bottom: 8px;
            cursor: pointer;
            background-color: $col-white;
            border-radius: 5px;
            box-shadow: 2px 3px 8px 0px #dddddd;
            outline: 1px solid transparent;
            transition: outline 0.15s ease;

            &:hover {
                outline: 1px solid $col-pret-blue;
            }

            &:active {
                outline: 4px solid $col-pret-blue;
            }

            &.selected {
                outline: 4px solid $col-pret-blue;
            }

            .folder-image {
                display: block;
                margin: 0 auto;
                width: auto;
                height: 80px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: 50% 50%;
            }

            .folder-name {
                height: 63px;
                p {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                    font-size: 0.7em;
                    text-align: center;
                    margin-top: $pad-xsm;
                }
            }

            .folder-upsell {
                display: flex;
                align-items: center;
                position: absolute;
                padding: 6px;
                background: $col-red;
                border-radius: 0 0 5px 5px;
                opacity: 80%;
                left: 0;
                width: 150px;
                bottom: 0;
                p {
                    flex-grow: 1;
                    color: white;
                    display: block;
                    font-size: 0.8em;
                    text-align: center;
                }
            }

            .folder-image-add-sitting {
                display: block;
                height: 50px;
                background-size: auto;
                background-repeat: no-repeat;
                background-position: 50% 0%;
            }

            .folder-name-add-sitting {
                position: relative;
                top: 35px;
                height: 73px;
                p {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                    font-size: 0.8em;
                    color: $col-grey-dark;
                    text-align: center;
                    margin-top: $pad-xsm;
                }
            }
        }
    }

    .loading-indicator {
        top: 75px;
    }
}

@media (max-width: $brk-mob-max) {
    .album-image-folders-container .album-image-folders {
        .folder {
            margin: 5px;
            min-width: 120px;
            min-height: 130px;
            width: 120px;
            height: 130px;

            .folder-image {
                height: 45px;
            }
    
            .folder-name {
                height: 58px;
                p {
                    font-size: 0.6em;
                }
            }

            .folder-upsell {
                width: 120px;
                p {
                    font-size: 0.7em;
                }
            }

            .folder-image-add-sitting {
                height: 45px;
            }

            .folder-name-add-sitting {
                top: 18px;
                height: 50px;
                p {
                    font-size: 0.6em;
                }
            }
        }

        .loading-indicator {
            top: 80px;
        }
    }

    .album-image-folder-scroll {
        padding-bottom: 5px;
    }
}