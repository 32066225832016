@import 'config.scss';
@import 'mixins.scss';
$hgt-modal-header: 40px;


.modal-root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: RGBA(25, 25, 25, .5);
    z-index: 999;

    .modal {
        background-color: #F3F3F3;
        width: 100%;
        height: 100%;
        text-align: center;
        margin: 0 auto;
        overflow-y: auto;

        .modal-header {
            text-align: center;
            position: absolute;
            top: 0;
            width: 100%;
            height: $hgt-modal-header;
            @include BoxShadowBottom();
            z-index: 1;

            .modal-header-element {
                display: inline-block;
                font-size: 1.3em;
                @include VerticalAlignMiddle();
            }
        }

        .modal-content {
            padding-top: $hgt-modal-header;
            height: 100%;
            position: relative;

            .album-image {
                cursor: pointer;
            }
        }

        .modal-scroll {
            height: 100%;
            overflow-y: auto;
            padding: $pad-lg $pad-lg;
            @include StyleScrollbar();

            .button-back {
                margin-bottom: $pad-lg;
            }
        }

        .pack-product {
            text-align: left;
        }

        .modal-picker {
            top: 10px;
        }

        .photo-album {
            width: 90%;
            margin: 0 auto;
        }

        .cancel-button, .add-to-basket, .selection-amt {
            display: inline-block;
        }

        .button {
            /*margin: 0;*/
        }

        .cancel-button {
            float: left;
            width: auto !important;
        }

        .continue {
            float: right;
        }

        .add-to-basket {
            float: right;
            width: auto !important;
        }

        .selection-amt {
            font-size: 1em;
            background-color: #4B77B1;
            padding: 5px 30px;
            color: white;
            border-radius: 10px;
            margin: 0 auto;
            position: relative;
            top: 5px;
        }

        .selection-amt-lg {
            display: none;
        }

        .selection-amt-sm {
        }

        .picture-selected {
            border: 4px solid #3BB879;
            position: relative;

            .quantity-changer {
                position: absolute;
                bottom: $pad-xsm;
                left: 0;
                right: 0;

                .button {
                    // Removes the default spacing a block element has
                    margin: -5px;
                }
            }
        }

        .modal-text-block {
            padding: 20px !important;
        }

        .modal-add-sitting-text {
            padding: 20px;
        }

        .modal-button-box {
            display: flex;
            gap: 30px;
            justify-content: center;
            padding: 10px;
        }
    }

    .modal:has(.modal-add-sitting) {
        top: 20%;
        width: 80%;
        height: fit-content;
    }
}

@media(min-width: 574px) {
    .modal-root {
        padding-bottom: 50px;
    }
}

@media (min-width: 364px) {
    .products-pack {
        .pack-product {
            max-height: 107px;
        }
    }
}

@media (max-width: 450px) {
    .modal-root {
        .modal {
            .modal-header {
                .modal-header-element {
                    font-size: 1.0em;
                }
            }
        }
    }
}

@media (min-width: 444px) {
    .selection-amt-lg {
        display: inline-block;
    }

    .selection-amt-sm {
        display: none;
    }
}

@media (min-width: $brk-mob-lg) {
    /*.modal-root {
        .modal {
            .photo-album {
                -moz-column-count: 3;
                -webkit-column-count: 3;
                column-count: 3;
            }
        }
    }*/

    .modal-root {
        .modal {
            width: 98%;
            height: 95%;
            @include VerticalAlignMiddle();
        }

        .modal:has(.modal-pack-reminder) {
            width: 50%;
            max-width: 1000px;
            min-width: 400px;
            height: auto;
        }

        .modal:has(.modal-login-reminder) {
            width: 40%;
            max-width: 800px;
            min-width: 400px;
            height: auto;
        }

        .modal:has(.modal-add-sitting) {
            width: fit-content;
            height: fit-content;
        }
    }
}

/* Progress bar */
progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
   appearance: none;

  display: block;
  width: 100%;
  height: 20px;
}

progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 0;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset; */
  border: 1px solid darkgrey;
}

progress[value]::-webkit-progress-value {
  background-color: #3063a5;
}

progress[value]::-moz-progress-bar {
  background-color: #3063a5;
}