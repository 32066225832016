﻿@import 'config.scss';

@mixin VerticalAlignMiddle() {
    position: relative;
    top: 50%;
    @include Transform(translateY(-50%));
}

@mixin DefaultButtonStyle() {
    @include Transition(all .2s ease);
    min-height: $hgt-inputs;
    padding: 11px $pad-sm;
    background-color: $col-pret-blue;
    color: $col-white;
    display: inline-block;
    text-align: center;
}

@mixin DefaultLinkStyle() {
    color: $col-pret-blue;

    &:visited {
        color: $col-pret-blue;
    }

    &:hover {
        color: lighten($col-pret-blue, $lighten-pct);
    }

    &:focus {
        color: $col-pret-blue;
    }

    &:active {
        color: $col-pret-blue;
    }
}

@mixin BoxShadowBottom() {
    -webkit-box-shadow: 0 2px 8px -4px #000000;
    -moz-box-shadow: 0 2px 8px -4px #000000;
    box-shadow: 0 2px 8px -4px #000000;
}

@mixin StyleButton($baseColor, $visitedColor, $hovercolor, $visitedcolor, $activeColor) {
    background-color: $baseColor;

    &:visited {
        background-color: $visitedColor;
    }

    &:hover {
        background-color: lighten($hovercolor, $lighten-pct);
    }

    &:focus {
        background-color: $visitedcolor;
    }

    &:active {
        background-color: $activeColor;
    }
}

@mixin StyleLink($baseColor, $visitedColor, $hovercolor, $visitedcolor, $activeColor) {
    color: $baseColor;

    &:visited {
        color: $visitedColor;
    }

    &:hover {
        color: lighten($hovercolor, $lighten-pct);
    }

    &:focus {
        color: $visitedcolor;
    }

    &:active {
        color: $activeColor;
    }
}

@mixin Transition($transition) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin Transform($transform) {
    -moz-transform: $transform;
    -ms-transform: $transform;
    -o-transform: $transform;
    -webkit-transform: $transform;
    transform: $transform;
}

@mixin AnimateHeight($closed-max-height, $max-height, $open-class) {
    &.animate {
        max-height: $closed-max-height;
        overflow: hidden;
        @include Transition(max-height 1s ease);

        &.#{$open-class} {
            @include Transition(max-height 1s ease);

            @if($max-height != 'null') {
                max-height: $max-height;
            }
        }
    }
}

@mixin StyleScrollbar() {
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: lighten($col-pret-blue, 45%);
    }

    &::-webkit-scrollbar-thumb {
        background-color: lighten($col-pret-blue, 10%);
    }
}

@mixin Size($height, $width) {
    height: $height;
    width: $width;
}

@mixin FullWidth() {
    width: 100%;
}