@import 'config.scss';

.modal {
  position: absolute;
  z-index: 9999;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;

  // background-color: blue;

  background-color: rgba(255, 255, 255, 0.9);

  /* if backdrop support: very transparent and blurred */
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  .modal_container {
    margin: auto;

    max-width: 1024px;
    padding: 2em;

    // text-align: center;

    background: white;

    h3 {
        font-weight: bold;
        text-align: center;
        padding-bottom: 1.5em;
    }

    p {
        padding-bottom: 1em;

    }

    ul {

        li {

            input {
                width: 1.5em;
            }

            padding-left: 1.5em;
            text-indent: -1.5em;

        }
    }

    .error {
        color: $col-red;
    }
  }
}

@media(min-width: $brk-mob-lg) {
  .modal {
    .modal_container {
      margin: 2em auto;
      padding: 2em 6em;
      width: 90%;
    }
  }
}