@import 'config.scss';

.add-sitting-field {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    z-index: 910;
    bottom: 8px;
    right: 0;

    .sitting-input {
        display: flex;
        width: 0;
        transition: 0.3s ease;

        button, a {
            min-width: 70px;
            width: 70px;
        }
    }
    
    button, a {
        vertical-align: top;
        position: relative;
        min-width: 40px;
        right: 0;
    }

    #add-sitting-toggle-button {
        height: 40px;
        font-weight: 700;
        white-space: nowrap;
        transition: .3s ease;
    }
}

.add-sitting-mask {
    position: absolute;
    right: -300px;
    bottom: 0;
    width: 300px;
    height: 56px;
    background: $col-grey;
    z-index: 997;
}

@media (max-width: 1023px) {
    .add-sitting-show {
        display: none !important;
    }
}

@media (min-width: 1024px) {
    .add-sitting-hide {
        display: none !important;
    }
}