.culture {
    .culture-select {
        .culture-flag-en {
            &::before {
                position: absolute;
                left: 0;
                top: 0;
                width: 200px;
                height: 200px;
                background-image: url('/assets/images/icons/flag/en.png');
            }
        }
    }
}
