﻿$col-green: #60ad08;
$col-yellow: #ffa64d;

$col-coal: #252525;

$col-pret-blue: #3063A5;
$col-pret-blue-light: #d6e6f4;
$col-blue-light: #cedae4;
$col-red: #cd2929;

$col-white: #f9f9f9;
$col-grey: #ebebeb;
$col-grey-loading: #e3e0e0;
$col-grey-light: #F3F3F3;
$col-grey-dark: #a6a6a6;

$lighten-pct: 3%;

$pad-xsm: 5px;
$pad-sm: 10px;
$pad-med: 15px;
$pad-lg: 20px;
$pad-xl: 40px;

// This is used to declare the global padding we will use throughout the site. eg, all gutters etc.
$pad-global: $pad-lg;

$hgt-header: 60px;
$hgt-banner: 45px;
$hgt-inputs: 40px;
$hgt-img-wrapper: 150px;

$brk-mob-max: 468px;
$brk-mob-lg: 640px;
$brk-tab-med: 768px;
$brk-tab-lg: 876px;
$brk-desktop-xsm: 1024px;
$brk-desktop-sm: 1280px;
$brk-desktop-med: 1408px;
$brk-desktop-lg: 1600px;
$brk-desktop-xlg: 1770px;

$breakpoints: $brk-mob-max, $brk-mob-lg, $brk-tab-med, $brk-tab-lg, $brk-desktop-xsm, $brk-desktop-sm, $brk-desktop-med, $brk-desktop-lg, $brk-desktop-xlg;

$sel-input: 'input[type=text], input[type=password], input[type=email], input[type=tel], select, textarea, div.hosted-field iframe';
$sel-braintree: '#card-number, #cvv, #expiration-date';
$sel-headers: 'h1, h2, h3, h4, h5, h6';

.default-image-wrapper {
    height: $hgt-img-wrapper;
    width: $hgt-img-wrapper;
    background-color: $col-white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid $col-grey-dark;
    display: inline-block;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}