@import 'config.scss';
@import 'mixins.scss';

body {
    font-family: 'Century Gothic W01', "Century Gothic", CenturyGothic, AppleGothic, Tahoma, "Microsoft YaHei New", "Microsoft Yahei", "微软雅黑",宋体, SimSun, STXihei, "华文细黑", sans-serif;
    color: $col-coal;
    background-color: #F3F3F3;
    letter-spacing: .5px;
    margin-bottom: 60px;

    &.no-scroll {
        overflow-y: hidden;
    }

    -webkit-touch-callout: none;
    -webkit-user-select: none;
}

.footer-buffer {
    height: 70px;
}

hr {
    margin: $pad-global 0;
}

.ng-animate {
    -moz-transition: none;
    -o-transition: none;
    -webkit-transition: none;
    transition: none;
}

.display-inline {
    display: inline !important;
}

#main-container {
    margin-top: $hgt-header + ($hgt-banner) + $pad-global + 6px;

    &.album-container {
        margin-top: 330px;
    }

    &.favourites-container {
        margin-top: 315px;
    }
}

.content-wrapper-grey {
    padding: 10px 20px;
    background-color: $col-grey-light;
    text-align: center;
}

@media (max-width: 420px) {
    .content-wrapper-grey {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media (max-width: $brk-mob-max) {
    #main-container {
        &.album-container {
            margin-top: 280px;
        }

        &.favourites-container {
            margin-top: 280px;
        }
    }
}

[ng-click], [data-ng-click], a, button {
    &:not([disabled]) {
        cursor: pointer;
    }
}

/* #region Headings & Text */
#{$sel-headers}, p, pre {
    line-height: 1.2em;
}

h1 {
    font-size: 2em;
}

h2 {
    font-size: 1.8em;
}

h3 {
    font-size: 1.6em;
}

h4 {
    font-size: 1.4em;
}

h5 {
    font-size: 1.3em;
}

h6 {
    font-size: 1.2em;
}

label {
    vertical-align: middle;
}

.bold {
    font-weight: bold;
}

#{$sel-headers}, p, i, span, div, td {

    &.white {
        color: $col-white;
    }

    &.grey {
        color: $col-grey-loading;
    }

    &.coal, &.black {
        color: $col-coal;
    }

    &.blue {
        color: $col-pret-blue;
    }

    &.green, &.success {
        color: $col-green;
    }

    &.red, &.danger {
        color: $col-red;
    }

    &.yellow, &.warning {
        color: $col-yellow;
    }


}
/* #endregion */

/* #region Margin Classes */
.margin-reset {
    margin: 0;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-left-20 {
    margin-left: 20px;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-right-20 {
    margin-right: 20px;
}

.margin-top-5 {
    margin-top: 5px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-sides-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.margin-sides-20 {
    margin-left: 20px;
    margin-right: 20px;
}
/* #endregion */

/* #region padding Classes */
.padding-left-10 {
    padding-left: 10px;
}

.padding-left-20 {
    padding-left: 20px;
}

.padding-right-10 {
    padding-right: 10px;
}

.padding-right-20 {
    padding-right: 20px;
}

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-sides-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.padding-sides-20 {
    padding-left: 20px;
    padding-right: 20px;
}
/* #endregion */

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-1-5em {
    font-size: 1.5em;
}

.text-75em {
    font-size: .75em;
}

strong {
    font-weight: bold;
}

.danger, .cancel, .clear, red {
    color: $col-red;
}

.vertical-align-middle-legacy {
    vertical-align: middle;
}

.vertical-align-top {
    vertical-align: top;
}

.vertical-align-middle {
    @include VerticalAlignMiddle();
}

.text-align-center {
    text-align: center;
}

.left {
    float: left !important;
}

.right {
    float: right !important;
}

.centre-by-height {
    line-height: 2.5em;
}

.classic-link {
    text-decoration: underline;
    vertical-align: bottom;
}

.small-font {
    font-size: small !important;
}

.arial {
    font-family: Arial !important;
}

.italic {
    font-style: italic;
}

.bold {
    font-weight: bold;
}

ul {
    padding-top: 1em;
    padding-bottom: 1em;
}

li {
    padding-bottom: .75em;
}

ul.list-style-bullet {
    list-style-type: circle;
    list-style-position: inside;
}

ul.list-style-tick {

    li {
        list-style-type: none;
    }

    li:before {
        content: '✓ ';
    }
}

.clearfix:after {
   content: " "; /* Older browser do not support empty content */
   visibility: hidden;
   display: block;
   height: 0;
   clear: both;
}