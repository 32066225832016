@import 'config.scss';

.humane-jackedup {
    background: none;
    background-color: $col-pret-blue;
    color: $col-white !important;
    text-shadow: none !important;
}

.humane-error {
    background-color: $col-red !important;
    color: $col-white !important;
}

.humane-success {
    background-color: $col-green !important;
    color: $col-white !important;
}