@import 'config.scss';
@import 'mixins.scss';
$pad-category-header: $pad-sm;
$pad-product-container: $pad-sm;
$col-product-header: $col-pret-blue;
$col-product-header-hover: lighten($col-product-header, $lighten-pct);
$hgt-categories: 90px;
$hgt-product-closed: 180px;
$hgt-product-closed-lg: 190px;
$hgt-product-info: 90px;
$hgt-product-info-basket-count: 140px;
$hgt-product-info-sm: 60px;
$hgt-product-details-max: 120px;
$product-header-border-bottom-width: 4px;

#desktop-products {

    &.right {
        float: right;
    }
}

.strikethrough {
    text-decoration: line-through;
}

.mobile-pad {
    margin: 0 0 15px 15px;
}

.premium-price {
    margin-left: 0 !important;
}

.upsell {
    background-color: #fff;
    color: #ff0000;
    font-weight: bold;
    border-radius: 15px;
    padding: 5px 10px;
    font-size: smaller;
    margin-top: 5px;
    border: 1px solid #173965;
    overflow: hidden;
    width: 100%;
    display: block;
}

.products-list {
    padding-bottom: $pad-sm;

    .breaker {
        height: 2px;
    }

    .products-list-categories {
        position: relative;
        color: $col-white;
        height: $hgt-categories;
        padding-top: 0;
        /*margin-bottom: $pad-category-header * 2;*/
        .product-list-category-header {
            position: relative;
            width: 25%;
            display: inline-block;
            /*padding: $pad-category-header;*/
            height: $hgt-categories;
            text-align: center;
            line-height: 2em;
            border: 1px solid darken($col-grey-light, 10%);
            border-bottom-width: $product-header-border-bottom-width;
            cursor: pointer;

            // background-color: $col-product-header;
            background-color: $col-grey-dark;

            vertical-align: top;

            @include Transition(all .1s ease);

            &.disable {
                opacity: 0.4;
            }

            .product-icon {
                height: 40px;
                width: 40px;
            }

            .product-name {
                font-size: .8em;
            }

            .category-arrow {
                position: absolute;
                bottom: -(30px + ($product-header-border-bottom-width - 1));
                color: $col-product-header;
                font-size: 3em;
                left: 0;
                right: 0;

                &.active {
                    color: $col-product-header-hover;
                }
            }

            &:hover {
                background-color: $col-product-header-hover;
                border-bottom-width: $product-header-border-bottom-width * 2;
            }

            &.active {
                background-color: $col-product-header-hover;
                border-bottom-width: $product-header-border-bottom-width * 3;
            }

            h3 {
                position: relative;
                @include VerticalAlignMiddle();
                font-size: 1.3em;
            }
        }
    }
}



/* These are outside the .product class because they are also being used in other places (eg: basket) */
.product-title {
    font-size: 1.2em;
    margin-bottom: $pad-sm;
    display: inline-block;
    font-weight: bold;
}

.product {
    display: grid;
    font-size: .8em;
    margin-bottom: $pad-lg;
    text-align: left;
    /*padding: $pad-product-container;*/
    /*@include AnimateHeight($hgt-product-closed, 'null', open);*/
    background-color: $col-white;
    border-radius: 5px;
    box-shadow: 2px 3px 8px 0px #dddddd;
    outline: 1px solid #ffffff00;
    transition: outline 0.2s ease-in-out;
    padding: 35px 20px;

    &:hover {
        outline: 1px solid $col-pret-blue;
    }

    .product-actions {
        float: right;
        text-align: right;
    }

    .product-defaults {
        float: left;

        label {
            display: block;
            margin-bottom: $pad-xsm;
            font-size: .8em;
        }
    }

    .product-basket {
        margin-top: $pad-sm;
    }

    .buy-action {
        min-width: 80px;
        /*max-width: 100px;*/
    }

    .product-brief {
        margin-bottom: $pad-med;
        /*height: 70px;*/
        overflow: hidden;
        font-size: smaller;
    }

    .product-info {
        height: $hgt-product-info;
        float: left;
        margin-right: 20px;
        position: relative;

        .logo {
            @extend .default-image-wrapper;
            height: $hgt-product-info-sm;
            width: $hgt-product-info-sm;
        }

        &.product-info-basket-count {
            height: $hgt-product-info-basket-count;
        }
    }

    .product-pip {
        padding: 10px;
        .pip {
            background-size: contain;
            width: 150px;
            height: 150px;
        }
    }

    .product-price {

        &.button-height {
            height: $hgt-inputs;
        }

    }

    .product-details-and-options {
        padding-top: $pad-lg;

        .buy-action {
            /*margin-top:$pad-xsm;*/
            max-width: none;
            /*width: 100%;*/
        }

        .details {
            padding-top: $pad-lg;
        }

        .product-option {
            clear:both;
            padding-bottom: $pad-lg;
            position: relative;

            .option-icon {
                @include VerticalAlignMiddle();
                // position: absolute;
                right: 0;
            }

            label {
                width: 65px;
                display: inline-block;
                margin: $pad-xsm 0;
            }

            select {
                width: 60%;
                display: inline-block;
            }
        }
    }

    label, pre {
        font-size: .8em;
        white-space: pre-line;
    }

    .button-details {
        margin-top: $pad-sm;
        background-color: #cedae4;

        &.tertiary {
            color: $col-coal !important;
        }
    }

    .button-details, .pip {
        margin-left: 70px;
    }

    .products-list {
        padding-bottom: 0;
    }

    .product-details {
        float: left;
        /*width: 160px;*/
        width: 85px;
        /*max-height: $hgt-product-details-max;*/
    }

    .product-basket {
        text-align: center;
    }

    .product-picture-preview, .product-picture-preview-mobile {
        @extend .default-image-wrapper;
        height: 100px;
        width: 100px;
    }

    .product-picture-preview {
        margin: $pad-sm;
        display: none;
    }

    .product-picture-preview-mobile {
        margin: 10px 0 0 70px;
    }

    .child-product-wrapper {
        display: flex;
        gap: 12px;
        height: 150px;

        .button {
            height: 50px;
        }
    }

    .child-product-wrapper-premium {
        display: flex;
        gap: 12px;
        align-items: center;

        .button {
            height: 50px;
        }
    }

    .child-product-info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;
    }

    .product-name {
        overflow: auto;
    }

    &.premium {
        $pip-size: 70vw;

        .product-info {
            height: $pip-size;
            width: $pip-size;
            margin-bottom: $pad-global;

            .logo {
                width: $pip-size;
                height: $pip-size;
            }
        }

        .product-details {
            width: auto;
            display: block;
            max-height: none;
            float: none;
            margin-bottom: $pad-global;
        }

        .product-brief {
            height: auto;
            line-height: 1.2em;
        }

        .product-details-and-options {
            overflow: hidden;
        }

        .product-actions {
            float: none;
            text-align: left;
        }

        .picture-preview {
            min-height: 150px;
            min-width: 150px;
            height: 150px;
            width: 150px;
            margin: 0 0 !important;
            background-color: $col-white;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            border: 1px solid $col-grey-dark;
            display: inline-block;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            text-align: center;
            font-size: smaller;

            span {
                @include VerticalAlignMiddle();
            }
        }

        .product-note {
            font-weight: bold;
            font-size: 1em;
            margin-bottom: 0.5em;
        }

        .picture-container {
            height: 150px;
            width: 100%;
            vertical-align: top;
            position: relative;
            margin-bottom: 50px;

            .button {
                left: 160px;
                width: 70px;
            }

            .product-note-placeholder {
                /*position: absolute;
                left: 155px;
                top: 130px;*/
                margin-top: 5px;
                font-size: .8em;
            }
        }

        .picture-container-pack {
            height: 150px;
            width: 100%;
            vertical-align: top;
            position: relative;
            margin-bottom: 80px;

            .button {
                width: 70px;
                min-height: 50px;
            }

            .product-note-placeholder {
                /*position: absolute;
                left: 155px;
                top: 130px;*/
                margin-top: 5px;
                font-size: .8em;
            }
        }
    }
}

.product.premium {
    outline: none;
    transition: none;
}

@media (max-width: 420px) {
    .product {
        padding: 20px;
        padding-top: 25px;
        padding-bottom: 25px;
    }
}



.large-cancel-button {
    vertical-align: bottom;
    padding: 1.55em 2em;
}

.product-price {
    font-weight: bold;
    font-size: 1em !important;
    margin-bottom: 0;
    margin-top: 10px;
    display: block;
    color: $col-pret-blue;

    .from {
        font-weight: normal;
        font-size: .8em !important;
        display: block;
    }
}

/* Custom Media Queries for mobile products. */
/* ----------------------------------------- */

@media(min-width: 300px) {
    .product {
        .product-details {
            width: 60%;
        }
    }
}

@media (min-width: 340px) {

    .product {
        .product-details {
            width: 70%;
        }
    }
}

@media (min-width: 375px) {
    .product-option select {
        width: 70% !important;
    }

    .mobile-pad {
        margin: 0;
    }
}

@media (min-width: 450px) {

    .product {
        .product-details {
            width: 80%;
        }
    }

    .picture-chooser-wrapper .button {
        left: 175px;
        width: 150px !important;
    }
}

@media (min-width: 510px) {

    .product {
        .product-details {
            width: 53%;
        }
    }

    .product-picture-preview {
        display: block !important;
    }

    .product-picture-preview-mobile {
        display: none !important;
    }

    .product-option select {
        width: 85% !important;
    }
}

@media (min-width: 600px) {

    .product {
        .product-details {
            width: 63%;
        }
    }
}

@media (min-width: 640px) {
    .pip {
        width: 178px !important;
        height: 178px !important;
    }
}

@media (min-width: 720px) {
    .product {
        .product-details {
            width: 68%;
        }
    }

    .pip {
        width: 239px !important;
        height: 239px !important;
    }

    .button-details {
        min-width: 239px;
    }
}



/* ----------------------------------------- */

@media (min-width: $brk-mob-max) {
    .product {
        /*@include AnimateHeight(175px, 'null', open);*/
        .product-brief {
            /*height: 60px;*/
        }

        &.premium {
            $picture-preview-width: 200px;
            $pip-size: 360px;

            .product-info {
                height: $pip-size;
                width: $pip-size;

                .logo {
                    width: $pip-size;
                    height: $pip-size;
                }
            }

            .picture-preview {
                min-width: $picture-preview-width;
                min-height: $picture-preview-width;
                width: $picture-preview-width;
                height: $picture-preview-width;
            }

            .picture-container {
                height: $picture-preview-width;
                width: 100%;
                vertical-align: top;
                position: relative;
                margin-bottom: 50px;

                .product-note-placeholder {
                    left: $picture-preview-width + $pad-lg;
                    top: 130px;
                    font-size: .8em;
                }
            }

            .picture-container-pack {
                height: $picture-preview-width;
                width: 100%;
                vertical-align: top;
                position: relative;
                margin-bottom: 60px;

                .product-note-placeholder {
                    left: $picture-preview-width + $pad-lg;
                    top: 165px;
                    font-size: .8em;
                }
            }

            .child-product-wrapper {
                height: $picture-preview-width;
            }
        }
    }
}

@media (min-width: $brk-mob-lg) {

    .upsell {
        width: auto;
        display: table;
    }

    .large-cancel-button {
        padding: 1.35em 1.65em;
    }

    .product {
        font-size: 1em;

        .product-brief {
            /*height: 60px;*/
        }

        &.premium {
            $pip-size: 360px;

            .product-info {
                height: $pip-size;
                width: $pip-size;

                .logo {
                    width: $pip-size;
                    height: $pip-size;
                }
            }
        }
    }
}


/* --- Desktop products start here --- */
@media (min-width: $brk-desktop-xsm) {

    .product-price {
        font-size: 0.95em !important;
    }

    .product {
        font-size: 1em;
        overflow: auto;
        /*@include AnimateHeight($hgt-product-closed-lg, 'null', open);*/
        .product-details {
            float: left;
            width: 40%;
        }

        .button-details, .pip {
            margin-left: 100px;
        }

        .product-details-and-options {
            .buy-action {
                max-width: none;
                width: auto;
            }

            .product-option {
                padding-bottom: $pad-lg;

                .option-icon {
                    position: relative;
                    -moz-transform: none;
                    -ms-transform: none;
                    -o-transform: none;
                    -webkit-transform: none;
                    transform: none;
                }

                label {
                    width: 95px;
                    display: inline-block;
                    line-height: 1.25em;
                }

                select {
                    width: 61% !important;
                }
            }
        }

        .product-note-left {
            float: left;
        }

        .product-info {
            .logo {
                width: $hgt-product-info;
                height: $hgt-product-info;
            }
        }

        &.premium {
            $pip-size: 360px;

            .product-info {
                height: $pip-size;
                width: $pip-size;
                margin-bottom: 20px;

                .logo {
                    width: $pip-size;
                    height: $pip-size;
                }
            }

            .picture-chooser-wrapper {
                margin-left: $pip-size + $pad-lg;
                margin-right: $pip-size + $pad-lg;
            }
        }
    }
}

@media (min-width: $brk-desktop-sm) {

    .product {
        .product-details {
            width: 53%;
        }
    }

    .product-details-and-options {
        .product-option {
            select {
                width: 65% !important;
                margin-right: 1em;
            }
        }
    }

    .pip {
        width: 308px !important;
        height: 308px !important;
    }

    .button-details {
        min-width: 308px;
    }
}

@media (min-width: $brk-desktop-med) {
    .product {
        .product-details {
            width: 55%;
        }
    }

    .product-details-and-options {
        .product-option {
            select {
                width: 70% !important;
                margin-right: 1em;
            }
        }
    }

    .pip {
        width: 320px !important;
        height: 320px !important;
    }

    .button-details {
        min-width: 320px;
    }
}

@media (min-width: $brk-desktop-lg) {
    .product {
        .product-details {
            width: 60%;
        }
    }

    .product-details-and-options {
        .product-option {
            select {
                margin-right: 1em;
            }
        }
    }

    .pip {
        width: 350px !important;
        height: 350px !important;
    }

    .button-details {
        min-width: 350px;
    }

    .product-option select {}
}

@media (min-width: $brk-desktop-xlg) {
    .product {
        .product-details {
            width: 65%;
            /*&.small {
                width: 470px - $hgt-product-info-sm;
            }*/
        }
    }

    .product-details-and-options {
        .product-option {
            select {
                width: 80% !important;
                margin-right: 1em;
            }
        }
    }
}
