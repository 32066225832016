@import 'config.scss';

.overlay {
    position: fixed;
    width: 100%;
    height: 90px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 1;
    text-align: center;
    padding-top: 20px;
  }

.overlay-text {
    text-overflow: ellipsis;
    display: inline-block;
    width: 80%;
    text-align: center;
    color: $col-pret-blue;
  }