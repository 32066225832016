@import 'config.scss';

.cookie-message {
    background-color: #EBEBEB;
    padding: 10px;
    border: 1px solid #D9D9D9;

    p {
        width: 60%;
        margin: 0 auto;
    }
}

.plain-x {
    color: #808080;
    background-color: none !important;
    font-weight: bold;
    font-size: 1.5em;
    padding: 10px;
}

@media(max-width: $brk-tab-med) {
    .cookie-message {
        p {
            font-size: small;
            width: 100%;
        }

        h6 {
            font-size: smaller;
            font-weight: bold;
        }
    }
}
