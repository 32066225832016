@import 'mixins.scss';

.feedback {
    label.top {
        vertical-align: top;
    }

    .form-group {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 10px;
    }
}

@media (max-width: 640px) {
    .feedback {
        .radio {
            display: block;
            width: 80%;
            height: 30px;

            .checkable {
                float: right;
            }
        }

        label.textarea {
            width: 100%;
        }
    }

    .feedback-button {
        display: none;
    }
}

.feedback-button {
    display: block;
    z-index: 999;
    position: fixed;
    left: -42px;
    top: 50%;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
     -webkit-transform: translateY(-50%); 
    transform: translateY(-50%);

    -ms-transform:rotate(270deg); /* IE 9 */
  -moz-transform:rotate(270deg); /* Firefox */
  -webkit-transform:rotate(270deg); /* Safari and Chrome */
  -o-transform:rotate(270deg); /* Opera */
}

@media (max-width: 720px) {

    .feedback-button {
        display: none !important;
    }
}

@media (min-width: 721px) {
    .feedback-header-button {
        display: none !important;
    }
}