@import 'config.scss';
@import 'mixins.scss';


.packs {
    margin-top: 5px;

    .product {

        .product-info{
            float: right;
        }
    }
}

@media (min-width: 1280px) {
    .packs {
        position: absolute;
        margin-top: 5px;
        width: 60%;
        left: 20%;
    }
}