@import 'config';
$order-image-width: 170px;
$order-image-width-child: 120px;

@mixin order-item-meta($break) {
  width: $break - $order-image-width - ($pad-global*2);
}

.order-header {
  margin-bottom: 20px;
}

.order-on-hold-notice {
  //font-style: italic;
}

table.order-summary-block {
  margin-top: 20px;
  box-shadow: none;
  width: 70%;

  tr.order-summary-item {
    display: table-row;
    
    th {
      text-align: left;
      padding: 10px;
      border: none;
    }
  }
  
  .order-summary-item {
    margin: 0;
    
    td {
      background: #EBEBEB;
      padding: 10px;
      border: none;
    }
  }
}

.order-summary-block {
  box-shadow: 0px 8px 9px -8px $col-grey-dark;
  margin-bottom: 30px;
  padding-bottom: 20px;

  .order-summary-item {
    display: block;
    margin: 10px 0;

    &.margin-top-20-i {
      margin-top: 20px !important;
    }

    .order-summary-item-header {
      display: inline-block;
      width: 40%;
    }
    .order-summary-item-data {
      display: inline-block;
      width: 55%;
      vertical-align: text-top;
      font-weight: bold;
    }
  }
}

.order-order-items {
  .order-item {
    background-color: $col-white;
    box-shadow: 0px 8px 9px -8px #9c9c9c;
    margin-bottom: 30px;

    .order-item-image, .order-item-image-child, .order-item-meta {
      display: inline-block;
      padding: 10px
    }
    
    .order-item-reorder {
      width: 100%;
    }

    .order-item-image {
      max-width: 120px;
      vertical-align: top;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .order-item-image-child {
      max-width: 100px;
      vertical-align: top;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .order-item-meta {
      font-size: .9em;
      width: 220px;
    }
  }

  .stack {
    /* border-bottom: 1px solid #808080; */
    margin: 10px 0;
    padding-bottom: 5px;
    overflow: hidden;
    position: relative;

    .stacked-left {
      display: inline-block;
      text-align: left;
      font-weight: bold;

      &::after {
        content: "............................................................................................................................................................................";
        letter-spacing: 3px;
        font-size: 10px;
        color: #cecece;
        display: inline-block;
        vertical-align: bottom;
        padding-left: 10px;
        width: 0;
        white-space: nowrap;
      }
    }

    .stacked-right {
      display: inline-block;
      text-align: right;
      position: absolute;
      right: 0;
      bottom: 5px;
      background-color: $col-white;
      padding-left: 10px;
    }
  }
}

.rtl {
  .stack {
    .stacked-right {
      right: auto;
      left: 0;
    }
  }
}

@media (min-width: $brk-mob-max) {
  .order-order-items {
    .order-item {
      .order-item-image {
        max-width: $order-image-width;
      }

      .order-item-image-child {
        max-width: $order-image-width-child;
      }
    }
  }
}

@each $breakpoint in $breakpoints {
  @media (min-width: $breakpoint) {
    .order-order-items {
      .order-item {
        .order-item-meta {
          @include order-item-meta($breakpoint);
        }
      }
    }
  }
}

@media (min-width: $brk-mob-lg) {
  .order-order-items {
    .order-item {
      .order-item-meta {
        .stack {
          width: 300px;
        }
      }
    }
  }
}

@media(min-width: $brk-desktop-xsm) {  
  .order-summary-block {
    .order-summary-item {
      .order-summary-item-header {
        width: 250px;
      }
      .order-summary-item-data {
        width: auto;
      }
    }
  }
}

@media(min-width: $brk-desktop-sm) {
  table.order-summary-block {
    margin-top: 20px;
    box-shadow: none;
    width: 50%;
  }
}