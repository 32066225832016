html,
body {
  min-height: 100%;
}
.humane,
.humane-jackedup {
  position: fixed;
  -moz-transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  z-index: 100000;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}
.humane,
.humane-jackedup {
  font-family: Helvetica Neue, Helvetica, san-serif;
  font-size: 18px;
  letter-spacing: -1px;
  top: 20px;
  left: 30%;
  opacity: 0;
  width: 40%;
  color: #333;
  padding: 10px;
  text-align: center;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAACWCAYAAAAfduJyAAAABmJLR0QA/wD/AP+gvaeTAAAAIklEQVQokWNgYGCQZGJgYGDARTDSQnboGDqsnDt0DKWNLAAkiQFdC+vZNQAAAABJRU5ErkJggg==');
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0,0,0,0.1)), color-stop(1, rgba(0,0,0,0.2))) no-repeat;
  background: -moz-linear-gradient(top, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.2) 100%) no-repeat;
  background: -webkit-linear-gradient(top, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.2) 100%) no-repeat;
  background: -ms-linear-gradient(top, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.2) 100%) no-repeat;
  background: -o-linear-gradient(top, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.2) 100%) no-repeat;
  background: linear-gradient(top, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.2) 100%) no-repeat;
  background-color: #fff;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  text-shadow: 0 1px 1px rgba(255,255,255,0.8);
  -webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.5);
  box-shadow: 0 1px 2px rgba(0,0,0,0.5);
  -moz-transform: translateY(-100px);
  -webkit-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  -o-transform: translateY(-100px);
  transform: translateY(-100px);
}
.humane p,
.humane-jackedup p,
.humane ul,
.humane-jackedup ul {
  margin: 0;
  padding: 0;
}
.humane ul,
.humane-jackedup ul {
  list-style: none;
}
.humane.humane-jackedup-info,
.humane-jackedup.humane-jackedup-info {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADICAYAAAAp8ov1AAAABmJLR0QA/wD/AP+gvaeTAAAAR0lEQVQokWNISfn/n4mBgeE/EwMDAwMqQYQYmdoGlxgjI4rY//+Dx2nUFRsQZ2ALTrQQp8QL1DWeqASC014y7aCx8QwMDAwA1aZBIulmpvwAAAAASUVORK5CYII=');
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0,0,0,0.7)), color-stop(1, rgba(0,0,0,0.85))) no-repeat;
  background: -moz-linear-gradient(top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.85) 100%) no-repeat;
  background: -webkit-linear-gradient(top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.85) 100%) no-repeat;
  background: -ms-linear-gradient(top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.85) 100%) no-repeat;
  background: -o-linear-gradient(top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.85) 100%) no-repeat;
  background: linear-gradient(top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.85) 100%) no-repeat;
  background-color: #fff;
  color: #fff;
  text-shadow: 0 -1px 1px rgba(0,0,0,0.35);
}
.humane.humane-jackedup-success,
.humane-jackedup.humane-jackedup-success {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADICAYAAAAp8ov1AAAABmJLR0QA/wD/AP+gvaeTAAAASElEQVQokc2SMQ4AIAgDD9/K/79QVzWaENTownAJbWnA5SqACkA/Aiy59hczrGVC30Q7y57EmNU5NL5zwln50IMsfZMel+UBKtFBQSLWM9wLAAAAAElFTkSuQmCC');
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #62c462), color-stop(1, #57a957)) no-repeat;
  background: -moz-linear-gradient(top, #62c462 0%, #57a957 100%) no-repeat;
  background: -webkit-linear-gradient(top, #62c462 0%, #57a957 100%) no-repeat;
  background: -ms-linear-gradient(top, #62c462 0%, #57a957 100%) no-repeat;
  background: -o-linear-gradient(top, #62c462 0%, #57a957 100%) no-repeat;
  background: linear-gradient(top, #62c462 0%, #57a957 100%) no-repeat;
  background-color: #64ff64;
  color: #fff;
  text-shadow: 0 -1px 1px rgba(0,0,0,0.35);
}
.humane.humane-jackedup-error,
.humane-jackedup.humane-jackedup-error {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADICAIAAACmkByiAAAABmJLR0QA/wD/AP+gvaeTAAAAf0lEQVQokY2TOQ7AIAwER/5mivy/yRc2RQDhA0jhghFYO5bhuS+TZMAoIUMEhhH4loGhfu71cenM3DutWMsaeGKjv3zO5N17KLPJ0+fQD8cpv5uVLPo4vnX0PpXj0nuaaeVzdmw+yXG1O96n2p3kozB757Ni1Z5UPsU9SP8AeAG1kHXE+7RlPAAAAABJRU5ErkJggg==');
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #ee5f5b), color-stop(1, #c43c35)) no-repeat;
  background: -moz-linear-gradient(top, #ee5f5b 0%, #c43c35 100%) no-repeat;
  background: -webkit-linear-gradient(top, #ee5f5b 0%, #c43c35 100%) no-repeat;
  background: -ms-linear-gradient(top, #ee5f5b 0%, #c43c35 100%) no-repeat;
  background: -o-linear-gradient(top, #ee5f5b 0%, #c43c35 100%) no-repeat;
  background: linear-gradient(top, #ee5f5b 0%, #c43c35 100%) no-repeat;
  background-color: #ee5f5b;
  color: #fff;
  text-shadow: 0 -1px 1px rgba(0,0,0,0.35);
}
.humane-animate,
.humane-jackedup.humane-jackedup-animate {
  opacity: 1;
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
.humane-animate:hover,
.humane-jackedup.humane-jackedup-animate:hover {
  opacity: 0.7;
}
.humane-js-animate,
.humane-jackedup.humane-jackedup-js-animate {
  opacity: 1;
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
.humane-js-animate:hover,
.humane-jackedup.humane-jackedup-js-animate:hover {
  opacity: 0.7;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
}
