@import 'config.scss';
@import 'mixins.scss';

$img-size-mob: 90px;
$album-padding: $pad-med;
$album-img-size: 220px;
$album-img-size-sm: 140px;

// .event-year {
//     text-align: center;
//     padding-top: 5px;
// }
/* #region Albums */
// .album-folders {
//     height: 100%;
//     min-height: $img-size-mob + ($album-padding * 2);
//     text-align: left;
//     position: relative;

//     &:not(:last-of-type) {
//         padding-bottom: $pad-lg;
//     }


//     .sitting-title {
//         font-size: 1.5em;
//         padding: $pad-med 0;
//     }

//     .event-folder {
//         padding: $pad-med;
//         height: 100%;
//         min-height: $img-size-mob + ($album-padding * 2);
//     }
// }


.album-thumb {
    height: $img-size-mob;
    width: $img-size-mob;
    background-color: $col-white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid $col-grey-dark;
    display: inline-block;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-right: $album-padding;

    img {
        width: auto;
        height: auto;
    }
}

.album-info {
    display: inline-block;
    vertical-align: top;
    width: 100%;

    .album-title {
        font-size: 1.1em;
    }

    .album-description {
        margin-top: $pad-sm;
        font-size: .8em;
        overflow: hidden;
    }
}

.image-name {
    font-size: .5em;
    text-align: center;
    margin-top: $pad-xsm;
}

.album-images, .album-products {
    min-height: 150px;
    // width:20px;
    width: auto;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    margin-bottom: $pad-sm;
    padding: 15px;
    z-index: 0;

    /*padding: $pad-xsm;*/
    .album-image, .album-product {
        @extend .default-image-wrapper;
        position: relative;
        padding-right: 0 !important;

        .picture-unavailable {
            font-weight: 800;
            font-size: large;
            line-height: 1.2em;
            color: #00000085;
            text-shadow: 0px 0px 5px white;
            z-index: 80;
            cursor: 'not-allowed';
        }
    }

    .album-product {
        background-color: white; // Not pret white
        width: $hgt-img-wrapper*2 + 30px;

        border: 2px solid $col-pret-blue;
        box-shadow: 0 0 5px $col-pret-blue;

        div.product-image {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 110px;
            width: 110px;
            background-image: url("/assets/images/sample_product.jpg");
            background-position: center left;
            background-size: cover;
        }

        span.name {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background-color: $col-green;
            color: $col-white;
            padding: .5em;
            font-size: 0.9em;
        }

        span.description {
            position: absolute;
            right: 10px;
            top: 40px;
            width: 200px;

            overflow: none;

            font-size: .9em;
        }

        span.price-buy {
            position: absolute;
            bottom: 0;
            right: 0;
            font-weight: bold;
            display:inline-block;

            span {
                display: inline-block;
                margin: 0;
                padding: 11px 10px;

                &.blue {
                    background-color: $col-pret-blue;
                    color: $col-white;
                }

                &.strikethrough {
                    color: red;
                }
            }

            &.blue {
                background-color: $col-pret-blue;
                color: $col-white;
            }
        }
    }
}


.lightbox {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    height: calc(100% - 40px);
    background-color: RGBA(25, 25, 25, 0.5);
    z-index: 100;

    .cancel-button {
        position: fixed;
    }
}

.lightbox img, .lightbox a {
    z-index: 100;
}

.lightbox-image-container {
    position: relative;
    top: 20%;
}

.picture-unavailable-message {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    height: calc(100% - 40px);
    background-color: RGBA(25, 25, 25, 0.5);
    z-index: 100;

    .picture-unavailable-message-content {
        position: relative;
        top: 20%;
        background: white;
        box-shadow: 5px 5px 8px #00000030;
        width: 30%;
        min-width: 400px;
        margin: auto;

        div {
            padding: 35px;
        }

        p {
            padding: 5px;
        }
    }
}

@media (max-width: $brk-tab-med) {
    .lightbox img {
        width: auto;
        max-width: 90%;
    }

    .picture-unavailable-message {
        .picture-unavailable-message-content {
            top: 10%;
            width: auto;
            max-width: 90%;
            min-width: auto;
        }
    }
}

// @media (max-width: $brk-desktop-sm) {
//     .album-images {
//         .album-image {
//             height: $album-img-size-sm;
//             width: $album-img-size-sm;
//         }
//     }
// }

// @media(min-width: $brk-mob-max) {
//     .album-images {
//         width: $album-img-size;
//         height: $album-img-size;
//     }
// }

// @media(max-width: $brk-mob-max) {
//     .album-images {
//         width: 120px;
//     }
// }

@media (max-width: $brk-mob-max) {
    .album-images {
        width: 50%;
        height: auto;
        padding: 10px;
        margin-bottom: 0;
        .album-image {
            width: 100%;
            height: auto;
            aspect-ratio: 1 / 1;
        }
    }
}

// @media (min-width: $brk-mob-max) {
//     .album-images {
//         padding: 0;
//         /*margin: 0 $pad-xsm $pad-xsm $pad-xsm;*/
//         /*width: $album-img-size;*/
//     }

//     .album-folders {
//         min-height: $hgt-img-wrapper + ($album-padding * 2);


//         .event-folder {
//             min-height: $hgt-img-wrapper + ($album-padding * 2);
//         }
//     }

//     .album-thumb {
//         @include Size($hgt-img-wrapper, $hgt-img-wrapper);
//     }
// }


/* #endregion */

.upsells {
    h3 {
        font-size: 1.2em;
        padding-bottom: 8px;
    }

    &.open {
        max-height: 300px;
   }

    transition: max-height 1s;

    height: auto;
    max-height: 40px;

    position: fixed;
    background-color: rgba($col-red, 0.8);
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    margin-bottom: 50px;
    padding-top: 7px;
    overflow: hidden;
}

@media (max-width: $brk-desktop-sm) {
    .upsells {
        margin-bottom: 0;
        padding-top: 9px;
        padding-bottom: 9px;
        max-height: 50px;

        h3 {
            font-size: 1em;
        }
    }
}
