@import 'mixins.scss';
.loading-indicator {
    @include VerticalAlignMiddle();
    color: $col-grey-loading;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 100px !important;
    z-index:999;
}

.loading-preview {
    @include VerticalAlignMiddle();
    color: $col-grey-loading;
    opacity: 0.5;
    position: absolute;
    left: 43%;
    top: 38%;
    text-align: center;
    font-size: 100px !important;
    z-index:999;
}

.loading-inline {
    @include VerticalAlignMiddle();
    color: $col-grey-loading;
    opacity: 0.5;
    position: inline;
    text-align: center;
    text-align: center;
    font-size: 100px !important;
    margin: 100px;
    z-index:999;
}