@import 'config.scss';
@import 'mixins.scss';

.footer {
    display: none;
    height: 55px;
    position: fixed;
    width: 100%;
    background-color: $col-grey;
    bottom: 0;
    left: 0;
    z-index: 999;
    padding: 5px;
    box-shadow: 0 -3px 3px -3px #000;
    font-size: small;

    .footer-text {
        position: relative;
        top: 30%;
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .footer-link {
        margin: 0 0 0 1em;
    }

    .fa {
        margin-right: 4px;
    }

    .phone-number, .contact-details {
        float: left;
        text-align: left;
        margin: 0 0.4em;
    }

    .phone-number {
        font-size: 1.4em;
        margin-top: 4px;
        font-weight: bold;
    }

    .contact-details {
        line-height: 1em;
        margin-left: 8%;
    }

    .links {
        float: left;

        a {
            margin: 5px;
            top: 5px;
            font-size: 1.1em;
        }
    }

    .mobile-show {
        display: block;
    }

    .mobile-hide {
        display: none;
    }

    .cookie-message, .cookie-info {
        width: 74%;
        float: left;
        line-height: 1.2em;
    }

    .cookie-button {
        float: right;
        margin: 5px 4px 0 0;
    }
}

.underline {
    text-decoration: underline;
}

.front {
    z-index: 9999999999 !important;
}

@media (min-width: 575px) {
    .footer {
        display: block;
        height: 50px;

        .phone-number {
            margin-top: 11px;
        }

        .contact-details {
            margin: 5px;
            line-height: 1.2em;
        }

        .links {
            float: right;
            margin-top: 6px;

            a {
                font-size: 1.05em;
                margin: 0 2px;
                font-weight: bold;
                right: 10px;
                top: 10px;
            }
        }

        .cookie-message, .cookie-info {
            position: relative;
            top: 30%;
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            width: auto;
            float: none;
        }

        .cookie-button {
            float: none;
            margin: 3px 0 0 0;
        }
    }
}

@media (min-width: 720px) {
    .footer {
        font-size: smaller;

        .fa {
            margin-right: 4px;
        }

        .phone-number, .contact-details {
            float: left;
            text-align: left;
            margin: 4px 0.25em;
        }

        .phone-number {
            font-size: 1.8em;
            margin-top: 9px;
        }
    }

    .mobile-show {
        display: none !important;
    }

    .mobile-hide {
        display: block !important;
    }
}

@media (min-width: $brk-desktop-xsm) {
    .footer {
        font-size: initial;

        .phone-number, .contact-details {
            margin: 2px 0.5em;
        }

        .phone-number {
            font-size: 2em;
            margin-top: 6px;
        }

        .contact-details {
            line-height: 1.2em;
        }
    }

    .mobile-show {
        display: none !important;
    }

    .mobile-hide {
        display: block !important;
    }

    .links {
        a {
            top: 7px !important;
        }
    }
}