@import 'config.scss';
@import 'mixins.scss';

.header {
    height: auto;
    position: fixed;
    width: 100%;
    background-color: $col-grey-light;
    top: 0;
    left: 0;
    z-index: 9;

    img.logo {
        @include VerticalAlignMiddle();
        padding-left: 10px;
    }

    .header-mobile, .header-desktop {
        height: $hgt-header;
    }

    .header-desktop {
        .header-left {
            position: relative;
            left: 0;
            display: inline-block;
            height: 100%;
        }

        .header-menu {
            position: relative;
            float: right;
            height: 100%;
            display: flex;
            align-items: center;
            gap: 25px;
            font-weight: 700;
            color: $col-pret-blue;

            .culture {
                display: inline-block;
                vertical-align: top;
                margin-left: $pad-sm;

                .flag {
                    width: 22px;
                    vertical-align: middle;
                }
            }
        }
    }
    // These are used for the Animation for easy changes when adding menu items to the mobile menu
    $amt-menu-items: 13;
    $menu-item-line-height: 45px;
    $menu-item-padding-bottom: 3px;

    .header-mobile {

        a .logo {
            max-width: 50%;
        }

        .header-menu {
            position: relative;
            float: right;
            height: 100%;
            display: flex;
            align-items: center;
            gap: 15px;
            font-size: larger;
            font-weight: 700;
            color: $col-pret-blue;
            padding-right: 15px;
        }

        .menu-button {
            color: $col-pret-blue;
            font-size: 1.5em;
            z-index: 999;

            &.open {
                font-size: 2em;
                padding-top: 7px;
            }

            &.open, &.open:hover {
                color: $col-white;
                background-color: lighten($col-pret-blue, 10%);
            }

            &:hover {
                background-color: initial;
            }
        }

        .menu-mobile {
            position: absolute;
            top: 0;
            right: 0;
            width: 360px;
            z-index: 998;
        }

        ul.menu-list-mobile {
            padding: 0;

            color: white;
            /*
                I'm using the two variables so we can just increment
                the amt by 1 if we add items. This means we don't have
                to change the value passed here, the calcultion will work
                appropriately.
            */
            @include AnimateHeight(0, (($menu-item-line-height + $menu-item-padding-bottom) * ($amt-menu-items - 1) + 52), 'open');

            .button-icon {
                @include VerticalAlignMiddle();
                left: $pad-med;
                position: absolute;
            }

            li {
                padding-bottom: $menu-item-padding-bottom;
                background-color: $col-pret-blue;
                a {
                    font-size: 1.2em;
                    width: 100%;
                    line-height: $menu-item-line-height;
                    padding: 0 $pad-med;
                    position: relative;
                }

                &.bottom-border {
                    border-bottom: 2px solid white;
                }
            }
        }
    }
}

@media (max-width: $brk-desktop-sm) {
    .header-mobile {
        .language-li {
            position: relative;

            .culture {
                text-align: center;
                select {
                    min-width: 220px;
                }
                img {
                    position: absolute;
                    right: 100px;
                    top: 40%;
                    @include Transform(translateY(-50%));
                }
            }
        }
    }
}

@media (min-width: $brk-desktop-xsm) {
    .header-menu {
        font-size: 0.9em;
    }
}

@media (min-width: $brk-desktop-sm) {
    .header-menu {
        font-size: 1em;
    }

    #menu-download {
        display: none;
    }
}

@media (min-width: $brk-desktop-lg) {
    #menu-download {
        display: initial;
    }
}