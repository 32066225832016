@import 'config.scss';
@import 'mixins.scss';

.home-page-form {
    text-align: center;
    max-width: 840px;
    margin: auto;

    #{$sel-input} {
        font-size: 1.2em;
        color: #000;
        width: 500px;
        height: 50px;
        padding: 5px 10px;
        text-align: left;
        border: 2px solid #01B055;
        margin: 10px 0;
        border-radius: 10px;
    }

    @media (max-width: $brk-mob-lg) {
        #{$sel-input} {
            width: 400px;
        }

        #email-blurb {
            width: 350px !important;
        }
    }

    @media (max-width: $brk-mob-max) {
        #{$sel-input} {
            width: 300px;
        }

        #email-blurb {
            width: 250px !important;
        }
    }

    .invalid {
        border: 2px solid red !important;
    }

    .alert {
        color: red !important;

        p {
            color: red !important;
        }
    }

    .message {
        padding-top: 30px;
    }

    input[type=submit] {
        border: none;
        margin-top: 50px;
        padding: 15px 60px;
        font-size: 1.4em;
        text-align: center;
        border-radius: 10px;
    }

    #login-button {
        font-size: 1.2em;
        padding: 15px 40px;
    }

    .blue {
        background-color: $col-pret-blue;
        color: white;
    }

    .light-blue {
        background-color: $col-pret-blue-light;
        color: $col-pret-blue;
        margin-top: 15px !important;
    }

    p, h4 {
        color: $col-pret-blue;

        i {
            font-style: italic;
        }
    }

    #fresh-approach-container {
        pointer-events: none;
        height: 68px;
        transition: opacity 0.4s, height 0.8s;
    }

    #fresh-approach {
        padding-bottom: 40px;

        h4 {
            color: black;
        }
    }

    #seasonal-message-container {
        margin-bottom: 20px;
    }

    #go-back {
        font-size: 1.1em;
        padding: 15px 25px;
    }

    button {
        font-size: 1.4em;
        margin-top: 50px;
        padding: 15px 40px;
        border-radius: 10px;
    }

    .home-cookie-message {
        height: 80px;
        padding: 25px 0;
    }

    .home-sitting-input {
        padding: 120px 0 0;
    }

    .home-registered {
        padding: 100px 0;
    }

    .password-container {
        position: relative;
        left: 11px;

        .show-hide-button {
            position: relative;
            left: -35px;
        }
    }

    .email-container {
        position: relative;
        left: 10px;

        #email-blurb {
            position: absolute;
            background: white;
            right: 60px;
            top: 2px;
            width: 450px;
            padding: 25px;
            border-radius: 5px;
            box-shadow: 4px 4px 8px 0 #0000002b;
            z-index: 99;
        }

        .show-blurb-button {
            position: relative;
            left: -35px;
            color: #979797;
        }

        .close-button {
            position: absolute;
            right: 10px;
            top: 10px;
            color: $col-pret-blue;
        }
    }

    .preferences-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 20px 0;

        .preference {
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: left;
        }

        input {
            border-radius: 6px;
            width: 25px;
        }
    }

    .validation-code-input {
        display: flex;
        justify-content: center;
        gap: 10px;
        padding: 20px 0 0;

        input {
            width: 50px;
            text-align: center;
        }
    }

    .grey-link {
        color: $col-grey-dark;
        font-weight: normal;
        text-decoration: underline;
    }

    .grey-link:hover {
        color: $col-pret-blue;
    }

    #not-ready-sitting-message {
        p {
            margin-top: 6px;
        }
    }

    .not-ready-sitting-message-link {
        margin-bottom: 20px;
        text-decoration: underline;
    }

    .small {
        font-size: small;
    }

    #invalid-otp-message {
        margin-top: 30px;
    }

    .done-message {
        padding-top: 120px;
    }

    select {
        padding-left: 5px;
    }

    select:required:invalid {
        color: #757575;
    }

    option {
        color: black;
    }

    #notifyme-privacy {
        padding-top: 25px;

        p {
            max-width: 100%;
        }
    }

    #validation-help {
        padding: 25px 0;

        div {
            padding: 8px 0;
        }
    }

    #validate-email-message {
        padding: 10px 0 0;
    }
}

.preference-callout {
    font-size: 0.85em;

    p {
        max-width: 100%;
    }
}
