/*
    mGrid - A simple SASS grid solution.
*/
.container {
  width: 90%;
  height: auto;
  margin: 0 auto; }

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

[class*='col-'] {
    display: inline-block;
  float: left; }

[class*='col-']:after {
  clear:both; content: "";}

[class*='col-']:not(:last-of-type) {
  padding-right: 20px; }

[class*='col-'].force-gutter {
    padding-right: 20px; }

.col-no_pad {
    padding-right: 0 !important; }

.clear {
    clear: both; }


@media (max-width: 1279px) {
    [class*='col-'] {
        float: none;
        width: auto;
    }

    .container [class*='col-'], .container [class*='col-']:not(:last-of-type) {
        width: 100%;
        padding-right: 0;
        padding-bottom: 20px;
    }

    footer .container [class*='col-'] {
        padding-bottom: 0;
    }

    .desktop-show {
        display: none !important;
    }
}

@media (min-width: 1280px) {

    .desktop-hide {
        display: none !important;
    }
  .col-1 {
    width: 6.86666666667%; }
  .col-2 {
    width: 15.3333333333%; }
  .col-3 {
    width: 23.8%; }
  .col-4 {
    width: 32.2666666667%; }
  .col-5 {
    width: 40.7333333333%; }
  .col-6 {
    width: 49.2%; }
  .col-7 {
    width: 57.6666666667%; }
  .col-8 {
    width: 66.1333333333%; }
  .col-9 {
    width: 74.6%; }
  .col-10 {
    width: 83.0666666667%; }
  .col-11 {
    width: 91.5333333333%; }
  .col-12 {
    width: 100%; } }

/* #region Margin and Padding Classes */
.padding-5 {
  padding: 5px; }

.padding-top-5 {
  padding-top: 5px; }

.padding-right-5 {
  padding-right: 5px; }

.padding-bottom-5 {
  padding-bottom: 5px; }

.padding-left-5 {
  padding-left: 5px; }

.padding-10 {
  padding: 10px; }

.padding-top-10 {
  padding-top: 10px; }

.padding-right-10 {
  padding-right: 10px; }

.padding-bottom-10 {
  padding-bottom: 10px; }

.padding-left-10 {
  padding-left: 10px; }

.padding-15 {
  padding: 15px; }

.padding-top-15 {
  padding-top: 15px; }

.padding-right-15 {
  padding-right: 15px; }

.padding-bottom-15 {
  padding-bottom: 15px; }

.padding-left-15 {
  padding-left: 15px; }

.padding-20 {
  padding: 20px; }

.padding-top-20 {
  padding-top: 20px; }

.padding-right-20 {
  padding-right: 20px; }

.padding-bottom-20 {
  padding-bottom: 20px; }

.padding-left-20 {
  padding-left: 20px; }

.margin-5 {
  margin: 5px; }

.margin-top-5 {
  margin-top: 5px; }

.margin-right-5 {
  margin-right: 5px; }

.margin-bottom-5 {
  margin-bottom: 5px; }

.margin-left-5 {
  margin-left: 5px; }

.margin-10 {
  margin: 10px; }

.margin-top-10 {
  margin-top: 10px; }

.margin-right-10 {
  margin-right: 10px; }

.margin-bottom-10 {
  margin-bottom: 10px; }

.margin-left-10 {
  margin-left: 10px; }

.margin-15 {
  margin: 15px; }

.margin-top-15 {
  margin-top: 15px; }

.margin-right-15 {
  margin-right: 15px; }

.margin-bottom-15 {
  margin-bottom: 15px; }

.margin-left-15 {
  margin-left: 15px; }

.margin-20 {
  margin: 20px; }

.margin-top-20 {
  margin-top: 20px; }

.margin-right-20 {
  margin-right: 20px; }

.margin-bottom-20 {
  margin-bottom: 20px; }

.margin-left-20 {
  margin-left: 20px; }

/* #endregion */