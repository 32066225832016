@import 'config.scss';
@import 'mixins.scss';
$col-like-green: #3bb879;

.picture-basket {
    position: absolute;
    /*bottom: 23px;
    left: 7px;*/
    bottom: 28px;
    left: 2px;
    background-color: #3063A5;
    color: white;
    padding: 5px;
    border-top-right-radius: 6px;
    opacity: .9;
    z-index: 10;
    cursor: default;
}

.small-icon {
    font-size: smaller !important;
    vertical-align: middle !important;
    padding: 0 4px 4px 0 !important;
}

$chevron-width: 35px;

.filter-preview {
    margin: $pad-sm;
}

#sepia {
    color: #9D5837;
}

#black-and-white {
    color: #4d4a4a;
}

#colour {
    color: #2c0d5f;
}

.basket-item-image {
    display: inline-block;
}

.adjust-buttons {
    vertical-align: top;
    display: inline-block;
}

.basket-item-image-section {
    margin-bottom: 40px;
}

/*#crop {
    background-color: #082b05;
}*/

.image-basket-count, .image-basket-count-product, .image-basket-count-favourite {
    width: 50px;
    height: 30px;
    background-color: #3063A5;
    color: white;
    border-radius: 15px;
    padding: 8px 0 22px;
    position: absolute;
    font-size: smaller;
    margin: 5px;
    border: 1px solid #173965;

    span {
        font-size: 11pt !important;
        margin-left: 2px;
        font-weight: bold;
        margin-top: 5px;
        vertical-align: middle !important;
    }
}

.image-basket-count {
    bottom: 20px;
}

.album-images {
    .image-basket-count {
        bottom: 2px;
    }
}

.image-basket-count-favourite {
    bottom: -4px;
    right: 35px;
}

.preview-container {
    text-align: center;
}

.preview-picture-wrapper {
    float: none;
}

.picture-preview {
    width: auto;
    margin: 0 auto;
    position: relative;

    .arrow-left, .arrow-right {
        height: 100%;
        width: $chevron-width;
        top: 0;
        color: $col-grey-dark;
        cursor: pointer;
        font-size: 1.5em;

        &:hover {
            background-color: initial;
            color: $col-grey-dark;
        }
    }

    .arrow-left {
        left: -$chevron-width;
        background: rgba(235,235,235,1);
        background: -moz-linear-gradient(left, rgba(235,235,235,1) 0%, rgba(235,235,235,0) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(235,235,235,1)), color-stop(100%, rgba(235,235,235,0)));
        background: -webkit-linear-gradient(left, rgba(235,235,235,1) 0%, rgba(235,235,235,0) 100%);
        background: -o-linear-gradient(left, rgba(235,235,235,1) 0%, rgba(235,235,235,0) 100%);
        background: -ms-linear-gradient(left, rgba(235,235,235,1) 0%, rgba(235,235,235,0) 100%);
        background: linear-gradient(to left, rgba(235,235,235,1) 0%, rgba(235,235,235,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ebebeb', endColorstr='#ebebeb', GradientType=1 );

        .fa-chevron-left {
            position: absolute;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .arrow-right {
        right: -$chevron-width;
        background: rgba(235,235,235,1);
        background: -moz-linear-gradient(right, rgba(235,235,235,1) 0%, rgba(235,235,235,0) 100%);
        background: -webkit-gradient(right top, right top, color-stop(0%, rgba(235,235,235,1)), color-stop(100%, rgba(235,235,235,0)));
        background: -webkit-linear-gradient(right, rgba(235,235,235,1) 0%, rgba(235,235,235,0) 100%);
        background: -o-linear-gradient(right, rgba(235,235,235,1) 0%, rgba(235,235,235,0) 100%);
        background: -ms-linear-gradient(right, rgba(235,235,235,1) 0%, rgba(235,235,235,0) 100%);
        background: linear-gradient(to right, rgba(235,235,235,1) 0%, rgba(235,235,235,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ebebeb', endColorstr='#ebebeb', GradientType=1 );

        .fa-chevron-right {
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    img {
        width: 100%;
        max-width: 630px;
        max-height: 480px;
        object-fit: contain;
        margin: 0 auto;
        display: block;
    }
}

#preview-img-sticky {
    display: none;
    position: fixed;
    top: 109px;
    right: 4px;
    width: auto;
    height: auto;
    max-width: 120px;
    max-height: 120px;
    object-fit: contain;
    object-position: top right;
    z-index: 998; // One below modals
    pointer-events: none;
    border: 4px solid white;
    box-shadow: 2px 3px 8px 0 #dddddd;
}

.liked {
    color: white;
    /*background-color: $like-green;*/
    cursor: pointer;
    background-color: #C1C1C1;
    padding: 6px;
    position: absolute;
    font-size: .8em;
    top: 0;
    right: 0;
    z-index: 10;
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 25px -5px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 25px -5px rgba(0,0,0,1);
    box-shadow: 0px 0px 6px -2px rgba(0,0,0,1);
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;

    &:hover {
        -moz-transition: all .2s ease;
        -o-transition: all .2s ease;
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
        background-color: $col-green;
    }
}

.basket-quantity-changer {
    $hgt-quantity-changer: 40px;
    cursor: default;
    margin-bottom: 10px;

    .basket-quantity-amt {
        height: $hgt-quantity-changer + 1;
        display: inline-block;
        vertical-align: middle;
        background-color: #3063A5;
        text-align: center;
        min-width: $hgt-quantity-changer;
        border: 1px solid #3063A5;
        padding: 0 0.25em;
    }

    button, .button {
        height: $hgt-quantity-changer;
        vertical-align: bottom;
        display: inline-block;
        padding-top: 12px;
        padding-bottom: $pad-med;
        height: $hgt-quantity-changer;
        z-index: 0;
        width: auto;
        margin: 0 !important;
    }

    .basket-quantity-input {
        width: 50px;
        text-align: center;
        vertical-align: middle;
        border-bottom: 1px solid #3063A5;
        border-top: none;
        font-size: 1em;
        background: white;
    }
}

.quantity-changer {
    $hgt-quantity-changer: 35px;
    $wdth-quantity-changer: 41px;
    cursor: default;

    .quantity-amt {
        line-height: $hgt-quantity-changer;
        display: inline-block;
        vertical-align: middle;
        background-color: white;
        text-align: center;
        min-width: $wdth-quantity-changer;
        margin: -5px;
    }

    button, .button {
        vertical-align: middle;
        display: inline-block;
        min-height: 0;
        padding-top: 10px;
        padding-bottom: $pad-xsm;
        height: $hgt-quantity-changer;
        width: $wdth-quantity-changer;
        z-index: 1;
    }
}

.lower-tab {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    box-shadow: 2px 3px 8px 0 #dddddd;
    border-radius: 15px 15px 0px 0px;
    z-index: 998;

    p {
        padding: 8px 14px;
        color: $col-coal;
        font-weight: 800;
    }

    i {
        color: #dbdbdb;
    }
}

@media(min-width: 375px) {
    .image-basket-count-favourite {
        right: 60px;
    }
}

@media (min-width: 468px) {
    .display_img {
        width: 80%;
        /*padding: 5px;*/
    }
}

@media(min-width: $brk-desktop-xsm) {
    .image-basket-count-product {
        left: 18px;
    }

    .image-basket-count-favourite {
        right: 55px;
    }
}

@media(min-width: $brk-desktop-sm) {
    .image-basket-count-favourite {
        right: 80px;
    }
}

@media(min-width: $brk-desktop-med) {
    .picture-preview img {
        width: auto;
        margin: 0 auto;
    }
}

@media (max-width: $brk-mob-lg) {
    .preview {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }

    .picture-preview {
        width: 70%;
    }

    .picture-preview {
        .arrow-left, .arrow-right {
            position: absolute;
        }
    }
}

@media (max-width: $brk-desktop-sm) {
    .picture-preview img {
        max-height: 420px;
    }
}