@import 'config.scss';
@import 'mixins.scss';
@import "fixAndScroll";

$col-product-header: $col-pret-blue;
$col-product-header-hover: lighten($col-product-header, $lighten-pct);

a {
    text-decoration: none;
    vertical-align: top;
    font-weight: bold;
    @include DefaultLinkStyle();

    &.success, &.green {
        @include StyleLink($col-green, $col-green, $col-green, $col-green, $col-green);
    }

    &.error, &.danger, &.red, &.close, &.cancel, &.clear {
        @include StyleLink($col-red, $col-red, $col-red, $col-red, $col-red);
    }

    &.button {
        font-weight: normal;
        @include DefaultButtonStyle();
        @include StyleButton($col-pret-blue, $col-pret-blue, $col-pret-blue, $col-pret-blue, $col-pret-blue);

        &.success, &.green {
            color: $col-white;
            @include StyleButton($col-green, $col-green, $col-green, $col-green, $col-green);
        }

        &.error, &.danger, &.red, &.close, &.cancel {
            color: $col-white;
            @include StyleButton($col-red, $col-red, $col-red, $col-red, $col-red);
        }

        &.tertiary {
            color: $col-white;
            @include StyleButton($col-blue-light, $col-blue-light, $col-blue-light, $col-blue-light, $col-blue-light);
        }

        &.wide { /* Wide attention drawing buttons */
            padding: 10px 50px;
        }

        &.back {
            position: fixed;
        }

        span {
            color: inherit;
        }
    }
}

button {
    border: none;
    font-size: 1em;

    @include DefaultButtonStyle();
    @include StyleButton($col-pret-blue, $col-pret-blue, $col-pret-blue, $col-pret-blue, $col-pret-blue);

    &.success, &.green {
        color: $col-white;
        @include StyleButton($col-green, $col-green, $col-green, $col-green, $col-green);
    }

    &.white {
        border: 1px solid $col-coal;
        background-color: $col-white;
        color: $col-coal;
    }

    &.error, &.danger, &.red, &.close, &.cancel {
        color: $col-white;
        @include StyleButton($col-red, $col-red, $col-red, $col-red, $col-red);
    }

    &.tertiary {
        color: $col-white;
        @include StyleButton($col-blue-light, $col-blue-light, $col-blue-light, $col-blue-light, $col-blue-light);
    }

    &.font-fix {
        font-size: 1em;
    }

    &.padding-fix {
        padding-top: 0;
        padding-bottom: 0;
    }
}

#{$sel-braintree} {
    display: inline-block;
}

#{$sel-input}, #{$sel-braintree} {
    height: $hgt-inputs;
    background-color: $col-white;
    border-color: $col-pret-blue;
    border-style: solid;
    border-width: 1px;
    padding: 1px 0.5em;
    vertical-align: middle;

    &.input-small {
        height: 30px;
    }
}

input[type=checkbox] {
    width: 25px !important;
    height: 25px;
    background: white;
    border-style: solid;
    border-width: 1px;
    border-color: $col-pret-blue;
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=checkbox]:checked {
    background: url(/assets/images/icons/ok.png) center center no-repeat;
}

textarea {
    min-height: 90px;
}

/*@import url(//netdna.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.css);*/

/*** basic styles ***/

label.address-radio {
    position: relative;
    padding-left: 1.5em;
    display: block;
    line-height: 1.2em;

    & > input[type="radio"] {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
}

h1 {
    font-size: 1.5em;
}

container {
    width: 175px;
    margin-left: 20px;
}

.checkable {
    font-size: 1.5em;
    vertical-align: middle;
    margin-right: 5px;
}

[disabled] {
    opacity: .3;
}

.alert {
    color: red;
    margin: 0.5em 0;
}

.outerBox {
    margin: 0 auto;
}

.innerBox {
    width: 100% !important;
    margin: 1em auto;
    border: 1px solid #3063A5;
    background: #fff;
    padding: 2em 1em !important;
    text-align: center;
}

.middle {
    vertical-align: middle !important;
}

.contents {
    @include VerticalAlignMiddle();
    font-size: 1.25em;
}

.full-width {
    @include FullWidth();
}

.mobile-pay, .summary-mobile {
    display: inline-block;
    margin: 0.5em 0;
    width: 100%;
}

.row {
    display: table-row;
}

.cell {
    display: table-cell;
}

#terms {
    display: table-cell;
}

.terms-box {
    @include VerticalAlignMiddle();
    width: 4%;
    display: table-cell;
}

.pay, .add {
    width: 100%;
}

.summary {
    display: none;
}

.mobile_summary {
    display: block;

    padding-right: 18px !important;
    border: 1px solid #000;
    box-shadow: 2px 2px 2px #262323;
    padding: 1em;
    background-color: #fff;
    margin-bottom: 1em;
}

.delivery {
    width: 57.6666666667%;
}

.change-btn {
    display: inline-block;
    background: #fff !important;
    color: #000 !important;
    border: 1px solid #000;
    vertical-align: middle !important;
    @include DefaultButtonStyle();
    font-size: 1em;
    font-weight: normal;
    margin-left: 1em;
    float: right;
}

.checkout-heading {
    font-size: 1.25em;
    font-weight: bold;
}

.order-items {
    width: 100%;
}

.account-button {
    display: inline-block;
    width: 100%;
    float: right;
    margin: 0 0 1em 0;
}

.account-button:nth-child(1) {
    float: left !important;
}

.account-icon, .account-link {
    font-size: 1.25em;
    background: #fff;
    border: 1px solid #3063A5;
}

.account-icon i {
    width: 60px;
    padding-top: 1em;
    text-align: center;
    vertical-align: middle;
}

.account-link {
    border-left: none;
    text-align: center;
    vertical-align: middle;
    width: 100%;
}

.approval-item {
    margin: 1em 0;
    vertical-align: middle;
    text-align: center;
    width: 100%;
}

.approval-item img {
    height: 150px;
}

.approval-item textarea {
    width: 100%;
    height: 150px;
}

.approval-item a {
    width: 100%;
}

.divider {
    margin: 40px 0;
}

/*.sitting-input input {
    width: 65%;
}

.sitting-input a {
    float: right;
}

.sitting-input {
    margin: 0 0 1em 0;
}*/

.show-description {
    width: 100% !important;
    text-align: center;
    margin-bottom: 5px;
}

@media (min-width: $brk-desktop-sm) {
    .mobile-pay, .summary-mobile {
        display: none !important;
        margin: 0 !important;
    }

    .summary {
        display: inline-block;
        border: 1px solid #000;
        box-shadow: 2px 2px 2px #262323;
        padding: 1em;
        background-color: #fff;
        width: 40%;
    }

    .mobile_summary {
        display: none;
    }
}

@media (min-width: $brk-desktop-xsm) {
    .approval-item {
        display: table-cell;
        width: auto !important;
        padding: 1em 1em;
    }

    .approval-item a {
        margin: 0 1em;
    }

    .approval-item textarea {
        min-width: 300px;
    }

    .account-button {
        width: 40%;
        margin: 0 0 1em 5em;
    }

    .checkout-heading {
        font-size: 1.5em;
    }

    .delivery {
        width: 100%;
    }

    .divider {
        margin: 20px 0;
    }

    .outerBox {
        width: 600px;
    }

    .innerBox {
        width: 100%;
    }

    .contents {
        font-size: 1.75em;
    }

    // .pay {
    //     width: 4em !important;
    // }

    .add {
        width: 15% !important;
        float: right;
        margin: 1% 42% 2% 0;
    }

    .addresses {
        width: 100%;
    }

    .order-items {
        width: 40%;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }

    td, th {
        border: 1px solid #808080;
        text-align: left;
        padding: 8px;
        vertical-align: middle !important;
    }

    td.no-border {
        border: none;
        background-color: #F3F3F3;
    }

    th {
        background-color: #EBEBEB;
        font-weight: bold;
    }

    td {
        background-color: #FDFDFD;
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    select:focus,
    textarea:focus,
    input:focus {
        // font-size: 16px;
        background: #eee;
    }
}

#paymentMethods {
    li {
        display: inline-block;
        margin-right: 5px;

        button {
            background-color: $col-grey-dark;

            &.active {
                    background-color: $col-product-header-hover;
            }
        }
   }
}

#payment-cards-list {
    li {
    display: inline;

    i {
        font-size: 2em;
        color: $col-grey-dark;

        &.active {
                color: $col-product-header-hover;
        }
    }
    }
}

#card-provider {
    .ng-valid {
        color: green;
    }
}

#payment {
    display:block;
    margin-bottom: 400px;
}