﻿@import 'config.scss';
@import 'mixins.scss';

.fix-and-scroll {
    width: 100%;
    overflow-y: auto;
    text-align: center;
    padding: 16px;
    @include StyleScrollbar();
}

.offset-top {
    margin-top: 150px;
}

@media (max-width: $brk-tab-med) {
}

@media (max-width: $brk-tab-lg) {
}