@import 'config.scss';

body.rtl {
    .text-align-center {
        text-align: center !important;
    }

    [fix-and-scroll] {
        direction: ltr;
    }

    div {
        direction: rtl;
        text-align: right;
    }

    .header {
        .header-desktop {
            .header-left {
                float: left;
            }
        }
    }

    .premium {
        .product-details {
            float: none !important;
        }

        .product-actions {
            float: none !important;
            text-align: right;

            .buy-action {
                float: none !important;
            }
        }

        .picture-container {
            .button {
                left: 0;
                right: 220px;
            }
        }

        .picture-chooser-wrapper {
            .buy-action {
                float: none !important;
            }
            @media(min-width: $brk-desktop-xsm) {
                margin-left: 0;
                margin-right: 420px;
            }
        }
    }

    .image-basket-count {
        text-align: center;
    }

    .modal-premium-layout {
        text-align: center;
    }

    .album-thumb {
        float: right;
        margin-right: 0;
        margin-left: 15px;
    }

    .product {
        .product-details {
            text-align: right;
            float: right;
        }

        .buy-action {
            float: left !important;
        }

        .product-actions {
            float: left;
        }

        .product-defaults {
            float: right;
        }

        .product-info {
            float: right;
            margin-right: 0;
            margin-left: 10px;
        }

        .button-details, .product-price, .pip {
            margin-left: 0;

            @media (min-width: $brk-desktop-xsm) {
                margin-right: 100px;
            }
        }

        .product-details-and-options {
            .product-option {
                select {
                    @media (min-width: $brk-desktop-xlg) {
                        margin-right: 0;
                        margin-left: 1em;
                    }
                }
            }
        }
    }

    .products-list {
        .products-list-categories {
            .product-list-category-header {
                div {
                    text-align: center;
                }
            }
        }
    }

    input, select, a, button {
        direction: rtl;
    }

    .image-basket-count-favourite {
        text-align: center;
    }

    .change-btn {
        float: left;
    }

    payment div {
        direction: ltr;
    }

    .add-sitting-field {
        left: 0;
        right: auto;
    }

    .add-sitting-mask {
        left: -300px;
        right: auto;
    }

    .album-images {
        text-align: center;

        .album-image {
        }
    }

    .banner {
        direction: rtl;

        .banner-main {
            div {
                text-align: center;
            }
        }

        .banner-menu {
            float: left;
        }
    }

    .header-mobile {
        .language-li {
            .culture {
                img {
                    direction: ltr;
                    left: 100px;
                }
            }
        }
    }

    .sitting-title {
        padding-right: 15px;
    }

    .footer {
        direction: rtl;

        .footer-link {
            margin: 0;

            span {
                margin-right: 1em;
            }
        }
    }

    .home-sitting-input {
        div {
            direction: rtl;
            text-align: center !important;
        }

        input {
            text-align: right;
        }
    }

    .home-page-form {
        div {
            text-align: center;
        }
    }

    .password-container {
        right: 11px;
    }

    .home-registered {
        text-align: center;
    }
}
